import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import _toConsumableArray from "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/_@babel_runtime-corejs2@7.26.0@@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import _defineProperty from "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/_@babel_runtime-corejs2@7.26.0@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.number.constructor";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import callNotHandled from "./callNotHandled";
import { mapState } from 'vuex';
import { searchConfig } from "../../CallDrawerConfig";
import Search from "../Search";
import api from "@/api/dispatch";
import dictApi from "@/api/dict";
import * as _ from "lodash";
export default {
  components: {
    callNotHandled: callNotHandled,
    Search: Search
  },
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    pageSize: {
      type: Number,
      default: function _default() {
        return 10;
      }
    },
    total: {
      type: Number,
      default: function _default() {
        return 0;
      }
    },
    currentPage: {
      type: Number,
      default: function _default() {
        return 1;
      }
    }
  },
  watch: {
    timeType: function timeType(val) {
      var params = {
        mobile: this.mobile,
        timeType: val
      };
      this.$emit('search', params);
    },
    drawerState: function drawerState() {
      this.timeType = null;
      this.mobile = null;
    }
  },
  computed: _objectSpread({}, mapState({
    drawerState: function drawerState(state) {
      return state.drawer.drawerState;
    }
  })),
  data: function data() {
    return {
      searchConfig: searchConfig,
      options: [{
        value: null,
        label: '全部'
      }, {
        value: 0,
        label: '今天'
      }, {
        value: 1,
        label: '昨天'
      }, {
        value: 2,
        label: '近一周'
      }, {
        value: 3,
        label: '近一个月'
      }],
      timeType: null,
      mobile: null,
      dropdownlist: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    if (this.$can('SHOWMENU', 'monitoring.map') || this.$can('SHOWMENU', 'callmgr')) {
      this.$nextTick(function () {
        dictApi.queryQimoDictList().then(function (val) {
          var status = val.status,
            data = val.data;
          if (status === '0') {
            if (Array.isArray(data)) {
              _this.dropdownlist = _this.setArray(_toConsumableArray(data));
              _this.dropdownlist = _.sortBy(_this.dropdownlist, 'dicValue');
            }
          }
        }).catch(function (err) {
          return console.log(err);
        });
      });
    }
  },
  methods: {
    search: function search(obj) {
      var params = _objectSpread({
        mobile: this.mobile,
        timeType: this.timeType
      }, obj);
      for (var paramsKey in params) {
        if (!params[paramsKey]) {
          delete params[paramsKey];
        }
      }
      if (this.$can('SHOWMENU', 'callmgr')) {
        this.$emit('search', params);
      }
    },
    change: function change() {
      var params = {
        mobile: this.mobile,
        timeType: this.timeType
      };
      this.$emit('search', params);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.$emit('handleCurrentChange', val);
    },
    setArray: function setArray(arr) {
      var code = null;
      var array = [];
      (arr || []).forEach(function (res) {
        array = _.concat(array, res.children);
      });
      for (var item in array) {
        if (code !== array[item].parentCode) {
          code = array[item].parentCode;
        } else {
          array[item]['isDivided'] = false;
        }
      }
      return array;
    }
  }
};