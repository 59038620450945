import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import _toConsumableArray from "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/_@babel_runtime-corejs2@7.26.0@@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import _defineProperty from "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/_@babel_runtime-corejs2@7.26.0@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import all from "./callDrawerPage/all";
import api from '@/api/dispatch';
export default {
  components: {
    all: all
  },
  props: {
    showState: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  data: function data() {
    return {
      activeName: 'completedObj',
      completedData: {},
      callCount: 0,
      deElectrifyCount: 0,
      searchParams: {
        timeType: null
      },
      allLists: [],
      callLists: [],
      pageSize: 10,
      currentPage: 1,
      total: 0
    };
  },
  watch: {
    showState: function showState(val) {
      if (val) {
        this.currentPage = 1;
        this.init();
      } else {
        this.activeName = 'completedObj';
      }
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    var _this = this;
    this.$EventBus.$on('callPush', function (msg) {
      // A发送来的消息
      var params1 = _objectSpread(_objectSpread({
        callType: 'normal'
      }, _this.searchParams), {}, {
        current: _this2.currentPage,
        size: _this2.pageSize
      });
      _this.getData(params1, 'callLists');
      var params2 = _objectSpread(_objectSpread({}, _this.searchParams), {}, {
        current: _this2.currentPage,
        size: _this2.pageSize
      });
      _this.getData(params2, 'allLists');
    });
    this.$EventBus.$on('hangupPush', function (msg) {
      // A发送来的消息
      var params1 = _objectSpread(_objectSpread({
        callType: 'normal'
      }, _this.searchParams), {}, {
        current: _this2.currentPage,
        size: _this2.pageSize
      });
      _this.getData(params1, 'callLists');
      var params2 = _objectSpread(_objectSpread({}, _this.searchParams), {}, {
        current: _this2.currentPage,
        size: _this2.pageSize
      });
      _this.getData(params2, 'allLists');
    });
  },
  methods: {
    /**
     * 初始化数据
     */
    init: function init() {
      var params = _objectSpread(_objectSpread({}, this.searchParams), {}, {
        current: this.currentPage,
        size: this.pageSize
      });
      if (this.$can('SHOWMENU', 'callmgr')) {
        this.getData(params, 'allLists');
      }
    },
    /**
     * 请求数据
     * @param params {Object} 请求参数
     * @param type {String} 存储
     */
    getData: function getData(params, type) {
      var _this3 = this;
      api.queryCallRecordList(params).then(function (val) {
        var status = val.status,
          data = val.data;
        if (status === '0') {
          if (data.recordExpands !== null && data.recordExpands !== undefined) {
            _this3[type] = _toConsumableArray(data.recordExpands);
          }
          _this3.callCount = data.callCount;
          _this3.deElectrifyCount = data.deElectrifyCount;
          _this3.total = data.total;
        }
      }).catch(function (err) {
        return console.log(err);
      });
    },
    /**
     * 搜索
     * @param val 搜索参数
     */
    search: function search(val) {
      var params = _objectSpread(_objectSpread({}, val), {}, {
        current: this.currentPage,
        size: this.pageSize
      });
      this.searchParams = _objectSpread({}, val);
      this.getData(params, 'allLists');
    },
    callSearch: function callSearch(val) {
      if (!this.$can('SHOWMENU', 'callmgr')) {
        return;
      }
      var params = _objectSpread(_objectSpread({}, val), {}, {
        callType: 'normal',
        current: this.currentPage,
        size: this.pageSize
      });
      this.searchParams = _objectSpread({}, val);
      this.getData(params, 'callLists');
    },
    /**
     * 关掉弹出层
     */
    shutDown: function shutDown() {
      this.$store.commit('drawer/DRAWER_STATE', false);
    },
    /**
     * tabs 切换
     */
    handleClick: function handleClick(tab) {
      if (tab.name === 'waitingOrder') {
        var params = _objectSpread(_objectSpread({
          callType: 'normal'
        }, this.searchParams), {}, {
          current: this.currentPage,
          size: this.pageSize
        });
        this.getData(params, 'callLists');
      }
      if (tab.name === 'completedObj') {
        var _params = _objectSpread(_objectSpread({}, this.searchParams), {}, {
          current: this.currentPage,
          size: this.pageSize
        });
        this.getData(_params, 'allLists');
      }
    },
    /**
     * 分页
     * @param val 当前页数
     */
    handleCurrentChange: function handleCurrentChange(val) {
      this.currentPage = val;
      if (this.activeName === 'waitingOrder') {
        var params = _objectSpread(_objectSpread({
          callType: 'normal'
        }, this.searchParams), {}, {
          current: this.currentPage,
          size: this.pageSize
        });
        this.getData(params, 'callLists');
      }
      if (this.activeName === 'completedObj') {
        var _params2 = _objectSpread(_objectSpread({}, this.searchParams), {}, {
          current: this.currentPage,
          size: this.pageSize
        });
        this.getData(_params2, 'allLists');
      }
    }
  }
};