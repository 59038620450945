import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/_@babel_runtime-corejs2@7.26.0@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/api/admin/auth/login',
    method: 'post',
    data: data
  });
}
export function renewMyPassword(data) {
  return request({
    url: '/api/admin/auth/renewMyPassword',
    method: 'post',
    params: data
  });
}
export function getInfo(token) {
  return request({
    url: 'api/admin/user',
    method: 'get'
  });
}
export function getAdminUsers(params) {
  return request({
    url: 'api/admin/user/list',
    method: 'get',
    params: params
  });
}
export function logout() {
  return request({
    url: 'api/admin/auth/logout',
    method: 'post'
  });
}
//密码过期重置密码
export function resetPassword(data) {
  return request({
    url: 'api/admin/auth/renewPassword',
    method: 'post',
    headers: _objectSpread({}, data)
  });
}