import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.split";
import _slicedToArray from "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/_@babel_runtime-corejs2@7.26.0@@babel/runtime-corejs2/helpers/esm/slicedToArray.js";
import _defineProperty from "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/_@babel_runtime-corejs2@7.26.0@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/web.dom.iterable";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { asyncRoutes, constantRoutes } from '@/router';
import ability from "../../config/ability";

/**
 * Use meta.role to determine if the current user has permission
 * @param permissions
 * @param route
 */
function hasPermission(permissions, route) {
  if (route.meta && route.meta.permissions) {
    if (route.meta.permission) {
      return permissions.some(function (p) {
        return p.val === route.meta.permission;
      });
    }
    return route.meta.permissions.some(function (perm) {
      return permissions.some(function (p) {
        return p.val === perm;
      });
    });
  } else {
    return false;
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param permissions
 */
export function filterAsyncRoutes(routes, permissions) {
  var res = [];
  routes.forEach(function (route) {
    var tmpRoute = _objectSpread({}, route);
    if (hasPermission(permissions, tmpRoute)) {
      if (tmpRoute.children) {
        tmpRoute.children = filterAsyncRoutes(tmpRoute.children, permissions);
      }
      res.push(tmpRoute);
    }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, permissions) {
    var commit = _ref.commit;
    function privilegeToRules(privilegeList) {
      return privilegeList.map(function (privilege) {
        if (privilege.val) {
          var _privilege$val$split = privilege.val.split(':'),
            _privilege$val$split2 = _slicedToArray(_privilege$val$split, 2),
            resource = _privilege$val$split2[0],
            action = _privilege$val$split2[1];
          if (action) {
            return {
              subject: resource,
              actions: [action]
            };
          } else {
            return {
              subject: privilege.val,
              actions: ['SHOWMENU']
            };
          }
        } else {
          return {
            subject: 'nothing',
            actions: ['do']
          };
        }
      });
    }
    return new Promise(function (resolve) {
      var accessedRoutes;
      permissions = permissions || [];
      var rules = privilegeToRules(permissions);
      ability.update(rules);
      if (permissions.some(function (p) {
        return p.val === 'all';
      })) {
        // 有 'all' 权限，就可以访问所有动态路由
        accessedRoutes = asyncRoutes || [];
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, permissions);
      }
      permissions.map(function (i) {
        return i.val;
      }).join(', ');
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};