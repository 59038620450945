import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/Users/panzhijing/\u86CB\u5377\u79D1\u6280/\u86CB\u5377\u51FA\u884C - \u540E\u53F0/node_modules/_@babel_runtime-corejs2@7.26.0@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Table from '@/components/Table';
import { mapState } from 'vuex';
import api from '@/api/dispatch';
import moment from 'moment';
var isUndefined = function isUndefined(value) {
  return value !== undefined ? value : false;
};
export default {
  components: {
    Table: Table
  },
  data: function data() {
    var _this = this;
    return {
      isUndefined: isUndefined,
      drawer: false,
      tableConfig: [{
        prop: 'calltype',
        label: '通话类型',
        slot: true
      }, {
        prop: 'taggingText',
        label: '通话标记'
      }, {
        prop: 'agentname',
        label: '坐席'
      }, {
        prop: 'time',
        label: '通话时间',
        formatter: function formatter(value) {
          var duration = _this.isNull(value.ringingdate) ? _this.isDate(value.ringingdate) : '';
          return duration;
        }
      }, {
        prop: 'duration',
        label: '通话时长',
        formatter: function formatter(value) {
          var duration = isUndefined(value.begin) || isUndefined(value.end) ? _this.setDuration(value.begin, value.end) : '';
          return duration;
        }
      }],
      options: [{
        value: 'dialout',
        label: '去电'
      }, {
        value: 'normal',
        label: '来电'
      }],
      value: '',
      tableData: [],
      form: {
        timeType: ''
      },
      drawerData: {},
      callRecorddata: {},
      total: 0,
      page: 1,
      size: 20,
      loading: false
    };
  },
  computed: _objectSpread({}, mapState({
    drawerState: function drawerState(state) {
      return state.recordsDrawer.drawerData;
    }
  })),
  watch: {
    drawerState: function drawerState(val) {
      var drawer = val.drawer,
        data = val.data;
      this.drawer = drawer !== undefined ? drawer : false;
      if (data !== undefined) {
        this.drawerData = _objectSpread({}, data);
        var params = {
          mobile: data.callno !== undefined ? data.callno : ''
        };
        this.getData(params);
      }
    }
  },
  methods: {
    /**
     * 判断是否为空
     * @param 判断的数据
     * @returns {string|*} 判断结果
     */
    isNull: function isNull(val) {
      if (val !== null && val !== undefined) {
        return val;
      }
      return '';
    },
    /**
     * 请求数据
     * @param params {Object} 请求参数
     * @param type {String} 存储
     */
    getData: function getData(params, type) {
      var _this2 = this;
      var obj = _objectSpread({
        current: this.page,
        size: this.size
      }, params);
      this.loading = true;
      api.queryCallRecordList(obj).then(function (val) {
        var status = val.status,
          data = val.data;
        _this2.total = data.total;
        if (status === '0') {
          _this2.callRecorddata = data;
          var lists = data.recordExpands !== undefined ? data.recordExpands : [];
          _this2.tableData = lists;
        }
      }).catch(function (err) {
        return console.log(err);
      }).finally(function () {
        return _this2.loading = false;
      });
    },
    /**
     * 处理通话时间
     * @param begin 开始时间
     * @param end 结束时间
     */
    setDuration: function setDuration(begin, end) {
      if (begin && end) {
        return moment.utc(end - begin).format('HH:mm:ss');
      }
      return '';
    },
    /**
     * 处理时间
     */
    isDate: function isDate(time) {
      var days = moment(new Date().valueOf()).diff(time, 'days');
      if (days === 0) {
        return moment(time).calendar();
      } else {
        if (days < 2) {
          return moment(time).subtract(1, 'days').calendar();
        } else {
          return moment(time).format('YYYY/MM/DD HH:mm:ss');
        }
      }
    },
    close: function close() {
      var params = {
        drawer: false
      };
      this.$store.commit('recordsDrawer/DRAWER_STATE', params);
      this.drawerData = {};
      this.callRecorddata = {};
      this.form = {
        timeType: ''
      };
    },
    /**
     * 分页 页码
     * @param val
     */
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      var params = _objectSpread(_objectSpread({}, this.form), {}, {
        mobile: this.drawerData.callno !== undefined ? this.drawerData.callno : ''
      });
      if (this.form.callType !== undefined && !this.form.callType) {
        delete params.callType;
      }
      this.getData(params);
    },
    /**
     * 搜索
     */
    searchChange: function searchChange() {
      var params = _objectSpread(_objectSpread({}, this.form), {}, {
        mobile: this.drawerData.callno !== undefined ? this.drawerData.callno : ''
      });
      if (this.form.callType !== undefined && !this.form.callType) {
        delete params.callType;
      }
      this.getData(params);
    }
  }
};